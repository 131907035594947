export const fmAppMainPages = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  TRANSACTIONS: '/transactions',
  RECURRING_ITEMS: '/transactions/recurring_items',
  TRANSACTIONS_DETAILS: '/transactions/transaction_id/detail',
  PAYMENTS: '/payments',
  PAYMENT_DETAIL: '/payments/:payment_id/detail',
  DIRECT_DEBIT: '/direct_debit',
  MANGOPAY_DIRECT_DEBIT: '/mangopay_direct_debit',
  SIGN_UP: '/signup',
  PROFILE: '/profile',
  DEV_NOWHERE: '/i_do_not_exist',
} as const;
