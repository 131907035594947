import type {
  GetServiceProviderResidentsResultDto,
  PatchServiceProviderInviteStatusDto,
  PostServiceProviderDto,
  PutServiceProviderResidentsDto,
  ServiceProviderDto,
  ServiceProviderListDto,
  ServiceProvidersPaginatedQueryParams,
  ServiceProvidersQuery,
  ServiceProviderWithStatementDescriptorDto,
} from '@pflegenavi/shared/api';
import { endpoints, ServiceProviderInviteStatus } from '@pflegenavi/shared/api';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getApiBaseUrl,
  getPhoenixApiTenantUrl,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { PaginatedResultSet } from '@pflegenavi/shared/utils';

export interface IServiceProviderApi extends Api {
  getServiceProviders(
    opts: (ServiceProvidersQuery | undefined) & {
      nursingHomeId: string;
    }
  ): Promise<string[]>;

  getServiceProvidersPaginated(
    opts: ServiceProvidersPaginatedQueryParams
  ): Promise<PaginatedResultSet<ServiceProviderListDto>>;

  addServiceProvider({
    data,
    nursingHomeId,
  }: {
    data: PostServiceProviderDto;
    nursingHomeId: string | undefined;
  }): Promise<{
    id: string;
  }>;

  getServiceProvider(
    serviceProviderId: string,
    nursingHomeId: string
  ): Promise<ServiceProviderListDto>;

  getServiceProviderPhoenix(serviceProviderId: string): Promise<{
    data: ServiceProviderWithStatementDescriptorDto;
  }>;

  updateServiceProviderStatementDescriptor(params: {
    serviceProviderId: string;
    data: {
      statement_descriptor: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  inviteServiceProvider({
    serviceProviderId,
    email,
    nursingHomeId,
  }: {
    serviceProviderId: string;
    email: string;
    nursingHomeId: string;
  }): Promise<{
    success: boolean;
  }>;

  inviteServiceProviderPhoenix({
    serviceProviderId,
    email,
  }: {
    serviceProviderId: string;
    email: string;
  }): Promise<{
    success: boolean;
  }>;

  cancelServiceProviderInvite({
    serviceProviderId,
    nursingHomeId,
  }: {
    serviceProviderId: string;
    nursingHomeId: string;
  }): Promise<{
    success: boolean;
  }>;

  getServiceProviderResidents(
    serviceProviderId: string,
    nursingHomeId: string
  ): Promise<GetServiceProviderResidentsResultDto[]>;

  setServiceProviderResidents({
    serviceProviderId,
    nursingHomeId,
  }: {
    serviceProviderId: string;
    nursingHomeId: string;
    data: PutServiceProviderResidentsDto;
  }): Promise<{
    success: boolean;
  }>;
}

export class ServiceProviderApi implements IServiceProviderApi {
  private readonly baseUrl: string;
  private readonly phoenixBaseUrl: string;

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getApiBaseUrl(tenantId, apiUrl);
    this.phoenixBaseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }

  async getServiceProvider(
    serviceProviderId: string,
    nursingHomeId: string
  ): Promise<ServiceProviderDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderGet.replace(
        ':serviceProviderId',
        serviceProviderId
      )}`,
      {
        headers: this.headers,
      }
    );

    return result.json();
  }

  async getServiceProviders(
    opts?: ServiceProvidersQuery & {
      nursingHomeId: string;
    }
  ): Promise<string[]> {
    if (!opts?.nursingHomeId) {
      throw new Error('nursingHomeId is required');
    }

    const queryParams = new URLSearchParams();
    queryParams.append('nursingHomeId', opts.nursingHomeId);

    if (opts?.searchTerms) {
      queryParams.append('searchTerms', opts.searchTerms);
    }

    if (opts?.residentId) {
      queryParams.append('residentId', opts.residentId);
    }

    if (opts?.receiptType) {
      queryParams.append('receiptType', opts.receiptType);
    }

    const queryString = queryParams.toString();

    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsGetServiceProviders}?${queryString}`,
      {
        headers: this.headers,
      }
    );

    return result.json();
  }

  // eslint-disable-next-line complexity
  async getServiceProvidersPaginated(
    opts: ServiceProvidersPaginatedQueryParams
  ): Promise<PaginatedResultSet<ServiceProviderListDto>> {
    const queryParams = new URLSearchParams();
    queryParams.append('nursingHomeId', opts.nursingHomeId);
    queryParams.append('page', opts.page.toString());
    queryParams.append('pageSize', opts.pageSize.toString());

    if (opts.searchTerm) {
      queryParams.append('searchTerm', opts.searchTerm);
    }

    if (opts.receiptTypeId) {
      queryParams.append('receiptTypeId', opts.receiptTypeId);
    }

    if (opts.combinedStatus) {
      queryParams.append('combinedStatus', opts.combinedStatus);
    }

    if (opts.status) {
      // TODO: Remove this compatibility fallback logic once not needed anymore (frontend deployed)
      if (Array.isArray(opts.status)) {
        opts.status.forEach((status) => {
          queryParams.append('status[]', status);
        });
      } else if (!opts.combinedStatus) {
        queryParams.append('combinedStatus', opts.status);
      }
    }

    if (opts.inviteStatus && opts.inviteStatus.length > 0) {
      opts.inviteStatus.forEach((inviteStatus) => {
        queryParams.append('inviteStatus[]', inviteStatus);
      });
    }

    if (opts.globalServiceProviderId) {
      queryParams.append(
        'globalServiceProviderId',
        opts.globalServiceProviderId
      );
    }

    if (opts.additionalFields) {
      Object.entries(opts.additionalFields).forEach(([key, value]) => {
        queryParams.append(`additionalFields[${key}]`, `${value}`);
      });
    }

    const queryString = queryParams.toString();

    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsGetServiceProvidersPaginated}?${queryString}`,
      {
        headers: this.headers,
      }
    );

    return result.json();
  }

  async addServiceProvider({
    data,
    nursingHomeId,
  }: {
    data: PostServiceProviderDto;
    nursingHomeId: string;
  }): Promise<{
    id: string;
  }> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderCreate}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async inviteServiceProvider({
    serviceProviderId,
    email,
    nursingHomeId,
  }: {
    serviceProviderId: string;
    email: string;
    nursingHomeId: string;
  }): Promise<{
    success: boolean;
  }> {
    const data: PatchServiceProviderInviteStatusDto = {
      inviteStatus: ServiceProviderInviteStatus.Pending,
      email,
    };
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderInviteStatus.replace(
        ':serviceProviderId',
        serviceProviderId
      )}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async inviteServiceProviderPhoenix({
    serviceProviderId,
    email,
  }: {
    serviceProviderId: string;
    email: string;
  }): Promise<{
    success: boolean;
  }> {
    const data = {
      data: { email },
    };
    const result = await superFetch(
      this.authContext,
      `${this.phoenixBaseUrl}/service-provider/${serviceProviderId}/invite`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async cancelServiceProviderInvite({
    serviceProviderId,
    nursingHomeId,
  }: {
    serviceProviderId: string;
    nursingHomeId: string;
  }): Promise<{
    success: boolean;
  }> {
    const data: PatchServiceProviderInviteStatusDto = {
      inviteStatus: null,
    };
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderInviteStatus.replace(
        ':serviceProviderId',
        serviceProviderId
      )}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async getServiceProviderResidents(
    serviceProviderId: string,
    nursingHomeId: string
  ): Promise<GetServiceProviderResidentsResultDto[]> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderGetResidents.replace(
        ':serviceProviderId',
        serviceProviderId
      )}`,
      {
        headers: this.headers,
      }
    );

    return result.json();
  }

  async setServiceProviderResidents({
    serviceProviderId,
    nursingHomeId,
    data,
  }: {
    serviceProviderId: string;
    nursingHomeId: string;
    data: PutServiceProviderResidentsDto;
  }): Promise<{
    success: boolean;
  }> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.serviceProvider.replace(
        ':nursingHomeId',
        nursingHomeId
      )}/${endpoints.serviceProviderPutResidents.replace(
        ':serviceProviderId',
        serviceProviderId
      )}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async getServiceProviderPhoenix(serviceProviderId: string): Promise<{
    data: ServiceProviderWithStatementDescriptorDto;
  }> {
    const result = await superFetch(
      this.authContext,
      `${this.phoenixBaseUrl}/service-provider/${serviceProviderId}`
    );
    return result.json();
  }

  async updateServiceProviderStatementDescriptor({
    serviceProviderId,
    data,
  }: {
    serviceProviderId: string;
    data: {
      statement_descriptor: string;
    };
  }): Promise<{
    success: boolean;
  }> {
    const result = await superFetch(
      this.authContext,
      `${this.phoenixBaseUrl}/service-provider/${serviceProviderId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          data,
        }),
        headers: this.headers,
      }
    );
    return result.json();
  }
}
