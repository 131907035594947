import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { BaseData, LegalEntity } from '@pflegenavi/shared/api';

export interface INursingHomePhoenixApi extends Api {
  getLegalEntities(): Promise<{
    meta: {
      total_count: number;
    };
    data: LegalEntity[];
  }>;

  createLegalEntity(dataIn: {
    body: {
      data: {
        base_data: BaseData;
      };
    };
  }): Promise<{
    data: LegalEntity;
  }>;

  updateLegalEntity(dataIn: {
    params: {
      legalEntityId: string;
    };
    body: {
      data: {
        id: string;
        base_data: BaseData;
      };
    };
  }): Promise<{
    data: LegalEntity;
  }>;

  deleteLegalEntity(dataIn: {
    params: { legalEntityId: string };
  }): Promise<{ success: boolean }>;
}

export class NursingHomePhoenixApi implements INursingHomePhoenixApi {
  baseUrl: string;

  getLegalEntities: INursingHomePhoenixApi['getLegalEntities'];
  createLegalEntity: INursingHomePhoenixApi['createLegalEntity'];
  updateLegalEntity: INursingHomePhoenixApi['updateLegalEntity'];
  deleteLegalEntity: INursingHomePhoenixApi['deleteLegalEntity'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getLegalEntities = get({
      authContext,
      url: `${this.baseUrl}/legal-entity`,
    });

    this.createLegalEntity = modify({
      authContext,
      url: () => `${this.baseUrl}/legal-entity`,
      method: 'POST',
    });

    this.updateLegalEntity = modify({
      authContext,
      url: ({ legalEntityId }) =>
        `${this.baseUrl}/legal-entity/${legalEntityId}`,
      method: 'PATCH',
    });
    this.deleteLegalEntity = modify({
      method: 'DELETE',
      authContext,
      url: ({ legalEntityId }) =>
        `${this.baseUrl}/legal-entity/${legalEntityId}`,
    });
  }
}
