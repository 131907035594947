// Please sync with packages/frontend/feature-flags/src/FeatureFlagModal.tsx
export enum FeatureFlag {
  /** Permanent (for now) */
  CoinList = 'CoinList' /** Only exists for Drescher */,
  RecurringItems = 'RecurringItems' /** Can be removed after recurring items v3 */,
  DevTools = 'DevTools',

  /** Feature flag to be enabled */
  PaymentStatusBadge = 'PaymentStatusBadge',
  AddFamilyMember = 'AddFamilyMember',
  AccountingPaymentsNew = 'AccountingPaymentsNew' /** Keep for Caritas until autumn */,
  dev_ServiceProviderApp = 'dev_ServiceProviderApp' /** Keep for Caritas until autumn */,
  ServiceProviderPayments = 'ServiceProviderPayments' /** Keep for Caritas until autumn */,
  ResidentsPaymentOverview = 'ResidentsPaymentOverview' /** Keep for Caritas until autumn */,

  /** Still in development */
  ImportFamilyMembers = 'ImportFamilyMembers',
  EmployeeManagement = 'EmployeeManagement',

  /** Done but unreleased */
  dev_PaginatedReceiptTable = 'dev_PaginatedReceiptTable',
  /** Caritas change request - free of charge */
  dev_InitialDepositFinalSettlementConfirmation = 'dev_InitialDepositFinalSettlementConfirmation',
  /** Individual receipt dates; Also on SP side */
  dev_ReceiptBatchPhoenix = 'dev_ReceiptBatchPhoenix',

  /* Released, but include in communication to Samariterbund */
  dev_Edit_StatementDescriptor = 'dev_Edit_StatementDescriptor',
  /* Release without communication */
  dev_DeleteFamilyMember = 'dev_DeleteFamilyMember',

  dev_Improved_Resident_Navigation = 'dev_Improved_Resident_Navigation',

  /* Release with communication */
  dev_ReconciliationReports = 'dev_ReconciliationReports',

  /** Development */
  dev_NewAuthentication = 'dev_NewAuthentication',
  dev_NewAuthenticationFamilyMember = 'dev_NewAuthenticationFamilyMember',
  dev_NursingHomeReceiptForServiceProvider = 'dev_NursingHomeReceiptForServiceProvider',

  /** Feature to be removed (Keep for Drescher) */
  AccountingPayments = 'AccountingPayments',

  /** To be dropped */
  dev_AutomaticTransactionNumbering = 'dev_AutomaticTransactionNumbering',
  dev_CashPayer = 'dev_CashPayer',

  dev_ServiceProviderPhoenixApi = 'dev_ServiceProviderPhoenixApi',
  dev_StripeVisualization = 'dev_StripeVisualization',

  dev_LegalEntities = 'dev_LegalEntities',
}

export enum FeatureFlagStatus {
  Disabled,
  Enabled,
}
