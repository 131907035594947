import { PaymentInitiatedBy, PaymentSchedule } from './PendingCharge';
import {
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  Max,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other',
}

export enum PaymentStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Disputed = 'Disputed',
  Pending = 'Pending', // Not shown in frontend
}

export enum MangopayStatus {
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface PaymentDto {
  id: string;
  resident?: {
    id: string;
    firstName: string;
    lastName: string;
    gender: Gender;
  };
  familyMember?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  statementDescriptor: string | null;
  status: PaymentStatus;
  amount: number;
  currency: string;
  paymentType?: string;
  creationDate: Date;
  cancellationDate?: Date;
  initiatedBy?: 'familyMember' | 'system';
}

export class PaymentScheduleDto {
  @IsNotEmpty()
  @IsEnum(PaymentSchedule)
  schedule!: PaymentSchedule;

  @ValidateIf((o) => o.schedule === PaymentSchedule.Monthly)
  @IsInt()
  @IsNotEmpty()
  @Min(1)
  @Max(31)
  dayOfMonth?: number;

  @ValidateIf((o) => o.schedule === PaymentSchedule.Monthly)
  @IsOptional()
  @IsInt()
  @Min(1)
  maxAmount?: number;
}

export class PaymentInitiationDto {
  @IsNotEmpty()
  @IsEnum(PaymentInitiatedBy)
  paymentInitiation!: PaymentInitiatedBy;

  @ValidateIf((o) => o.paymentInitiation === PaymentInitiatedBy.System)
  @IsNotEmpty()
  @ValidateNested()
  paymentSchedule?: PaymentScheduleDto;
}

export interface PaymentQueryFilters {
  states?: PaymentStatus[];

  /**
   * If not set, it starts at the start of the current month
   */
  dateFrom?: Date;

  /**
   * If not set, it will end at the end of the current month
   */
  dateEnd?: Date;
}

export enum MoneyFlowType {
  ServiceProviderPayment = 'service_provider_payment',
  Payout = 'payout',
}

export enum MoneyFlowStatus {
  Completed = 'Completed',
  Disputed = 'Disputed',
  Paid = 'paid', // Intentionally small to match the payout state
}

export interface PaymentMoneyFlowEntry {
  nursingHome: {
    id: string;
    name: string;
  };
  date: Date;
  type: MoneyFlowType;
  amount: number;
  currency: string;
  ibanLast4: string;
  status: MoneyFlowStatus;
  serviceProvider?: {
    globalServiceProviderId: string;
    name: string;
    receiptType: {
      id: string;
      name: string;
    };
  };
  receiptBatch?: {
    id: string;
    receiptDate?: Date;
    useIndividualDates: boolean;
    earliestEntryDate?: Date;
    latestEntryDate?: Date;
  };
  employee?: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
}
